<div *ngIf="getCampaign">
    <div *ngIf="screen_desktop">
        <div class="screenBloqe align-items-center" style=" justify-items: center;"
            [ngClass]="{  'd-none': !screenBloqued,  'd-grid' : screenBloqued }">
            <img loading="lazy" [defaultImage]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'"
                [lazyLoad]="getCampaign.base_gif_load" width="70px" alt="">
        </div>

        <div [@fadeInAnimationSuave]="{ value: '', params: { height: '70px' } }"
            *ngIf="turboCDT && !is_amount_disables_turbocdt"
            class="rounded-top-2 text-white position-relative px-0 image-turbo">
            <img loading="lazy" [src]="env.S3_STATIC_FILES + '/images/web/turbocdt/turbo-banner-check-simulate.svg'"
                class="w-100 image-turbo">
        </div>

        <div class="modal-body modal-lg px-4" style="opacity: {{ opacityModalCheckParameters }} ">
            <div class="content-modal-theme">
                <div class="container-fluid px-0" id="">
                    <div id="modalBody">
                        <!-- <button class="btn-home-hover position-absolute btn-close-pamaters btn-close-confirm-parameters fw-bold text-white" (click)="activeModal.dismiss()">
                                <i class="bi bi-x-lg fw-bold"></i>
                            </button> -->
                        <div id="info-noticia">
                            <div class="row text-start pb-3 pt-0 px-2">
                                <div class="col-12">
                                    <div class="w-100 px-2">
                                        <div class="text-center">
                                            <div class="title-6 font-bold text-black-100 mt-2 lh-1 mb-0">
                                                Confírmanos los datos de tu inversión con
                                            </div>

                                            <img loading="lazy"
                                                [defaultImage]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'"
                                                [lazyLoad]="bank_image_view" alt=""
                                                class="img-thumbnail border-0 my-4 img-bank-check">
                                        </div>

                                        <div class="alert-info-signature-digital"
                                            *ngIf="!bankSettings?.digital_signature">
                                            Si deseas abrir tu CDT rápido y de forma completamente digital, te
                                            recomendamos iniciar tu proceso con uno de los bancos marcados con la opción
                                            Apertura digital
                                        </div>

                                        <form autocomplete="on" method="POST" [formGroup]="FormCheckParameters"
                                            class="mt-2">
                                            <div class="row">
                                                <div class="col-sm-7 position-relative">
                                                    <span class="fw-bold">Quiero abrir un CDT por</span>
                                                    <input #amount_input id="amount" type="text"
                                                        placeholder="Monto a consultar."
                                                        class="form-control mt-2 text-blue-100 fs-4 fw-bold entry-field-icon"
                                                        formControlName="amount" (keyup)="thousandSeparator($event)"
                                                        (change)="_getSimulationInvestment({
                                                                    amount: fCheckParam['amount'].value?.split('.').join(''),
                                                                    terms: terms,
                                                                    include_only:  [bankID_opencdt]
                                                                    })" [attr.tabindex]="-1" [ngClass]="{
                                                                'is-invalid': submitCheckParam && fCheckParam['amount'].value?.split('.').join('') < bank_amount_validate,
                                                                'is-valid' : fCheckParam['amount'].value?.split('.').join('') > bank_amount_validate-1
                                                        }">
                                                    <div class="invalid-feedback" [ngClass]="{
                                                            'd-block': submitCheckParam && fCheckParam['amount'].value?.split('.').join('') < bank_amount_validate,
                                                            'd-none' : fCheckParam['amount'].value?.split('.').join('') > bank_amount_validate-1
                                                        }"></div>
                                                </div>
                                                <div class="col-5 text-end align-self-end ps-0">
                                                    <span class="fw-bold">Que me entregará</span> <br>
                                                    <span class="fw-light text-black-50 interese-capital"
                                                        *ngIf="bankID_opencdt === globals_var.ID_FLAMINGO">(intereses +
                                                        capital)</span>
                                                    <div class="colorAmount fw-bold fs-3" [ngClass]="{
                                                      'strikethrough text-black-50': lineThroughInAmount
                                                    }">
                                                        ${{ amtGenereteCheckParameters | thousandSeparator }}
                                                    </div>
                                                </div>
                                            </div>

                                            <app-widget-event-bonus
                                              *ngIf="eventBonus"
                                              [amount]="fCheckParam['amount'].value?.split('.').join('')"
                                              [term]="this.bankTerm_opencdt"
                                              [rate]="this.bankRate_opencdt"
                                              [mobileDevice]="false">
                                            </app-widget-event-bonus>

                                            <hr class="my-3">

                                            <div class="row">
                                                <div class="col-sm-7 position-relative">
                                                    <span class="fw-bold">Por esperar un plazo de</span>
                                                    <select [(ngModel)]="selectedItem" name="selectedItem"
                                                        [ngModelOptions]="{standalone: true}" id=""
                                                        class="form-select fw-bold fs-4 text-blue-100 mt-2"
                                                        (change)="ChangeSelectValue()">
                                                        <option *ngFor="let item of terms" [ngValue]="item">
                                                            {{ item }} {{ item === 1 ? 'mes' : 'meses' }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-5 text-end align-self-end ps-0">
                                                    <span class="fw-bold">A una rentabilidad del</span>
                                                    <div class="colorAmount fw-bold fs-3">
                                                        {{ placeGenereteCheckParameters }}% EA
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row" *ngIf="bankID_opencdt === globals_var.ID_FLAMINGO">
                                                <hr class="my-3">
                                                <div class="col-12 position-relative advisor_tienda "
                                                    [ngClass]="{'mb-3':was_attended_store}">
                                                    <span class="fw-bold">¿Fuíste atendido en tienda?</span>

                                                    <input type="radio" id="btn-yes" name="button-advisor"
                                                        (change)="advisorStore(1)">
                                                    <label for="btn-yes" class="me-2">Si</label>

                                                    <input type="radio" id="btn-no" name="button-advisor"
                                                        (change)="advisorStore(2)">
                                                    <label for="btn-no">No</label>
                                                </div>
                                                <div class="col-12 advisor-question position-relative"
                                                    *ngIf="was_attended_store">
                                                    <span class="fw-bold">Por favor ingresa el número de identificación
                                                        del asesor</span>
                                                    <i class="bi bi-person-vcard-fill field-icon"></i>
                                                    <input type="text" class="form-control  fw-bold entry-field-icon"
                                                        formControlName="number_advisor">
                                                </div>
                                            </div>

                                            <div class="row"
                                                *ngIf="getCampaign?.name === globals_var.CAMPAIGN_CLAUDIAURIBE">
                                                <hr class="my-3">
                                                <div class="col-12 position-relative advisor_tienda "
                                                    [ngClass]="{'mb-3':was_attended_store}">
                                                    <span class="fw-bold">¿Ya tienes un coach financiero desde Money
                                                        Mindset Solutions?</span>

                                                    <input type="radio" id="btn-yes" name="button-advisor"
                                                        (change)="advisorStore(1)">
                                                    <label for="btn-yes" class="me-2">Si</label>

                                                    <input type="radio" id="btn-no" name="button-advisor"
                                                        (change)="advisorStore(2)">
                                                    <label for="btn-no">No</label>
                                                </div>
                                                <div class="col-12 advisor-question position-relative"
                                                    *ngIf="was_attended_store">
                                                    <span class="fw-bold">¿Cuál es tu coach financiero?</span>

                                                    <select formControlName="number_advisor" class="form-select">
                                                        <option *ngFor="let advisor of advisor_claudiauribe"
                                                            [value]="advisor.phone">
                                                            {{ advisor.nombre }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <hr class="my-3">

                                            <section>
                                                <div *ngIf="turboCDT && !is_amount_disables_turbocdt"
                                                    [@fadeInAnimationSuave]="{ value: '', params: { height: '47px' } }"
                                                    class="row align-content-center justify-content-between align-items-center">
                                                    <div class="col-7 text-start">
                                                        <span class="fw-bold">Y quiero hacer el proceso rápido de</span>
                                                    </div>

                                                    <div class="col-4 text-end px-0">
                                                        <img [lazyLoad]="env.S3_STATIC_FILES + '/images/web/turbocdt/logo-turbo-cdt.svg' "
                                                            [alt]="'Turbo CDT'" class="w-100">
                                                    </div>

                                                    <hr class="my-3">
                                                </div>

                                                <div [@fadeInAnimationSuave]="{ value: '', params: { height: '60px' } }"
                                                    *ngIf="(turboCDT) || ( !turboCDT && FormCheckParameters.get('method_pay_turbo')?.value == '2') ">
                                                    <span class="fw-bold"> Porque voy a realizar el pago usando </span>

                                                    <div class="form-group  mt-3 align-items-center d-flex">
                                                        <input type="radio" id="method_1" name="method_pay_turbo"
                                                            value="1" formControlName="method_pay_turbo"
                                                            class="custom-radio me-2 cursor-pointer"
                                                            (change)="deactiveTurboCDT($event)"
                                                            *ngIf="!is_amount_disables_turbocdt">
                                                        <label for="method_1" class="title-6 cursor-pointer"
                                                            *ngIf="!is_amount_disables_turbocdt">PSE</label>

                                                        <input type="radio" id="method_2" name="method_pay_turbo"
                                                            value="2" formControlName="method_pay_turbo"
                                                            class="custom-radio me-2 cursor-pointer" [ngClass]="{
                                                                'ms-5': !is_amount_disables_turbocdt
                                                            }" (change)="deactiveTurboCDT($event)">
                                                        <label for="method_2" class="cursor-pointer">Otro medio de
                                                            pago</label>
                                                    </div>

                                                </div>
                                            </section>

                                            <div class="row" *ngIf="this.cookies.get('refcode')">
                                                <div class="col-12 mt-2">
                                                    <div
                                                        class="d-flex justify-content-between align-items-center bg-blue-100 rounded-3 p-2">
                                                        <img class="w-25"
                                                            [defaultImage]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'"
                                                            [lazyLoad]="env.S3_STATIC_FILES + '/images/web/landings/referidos/logo-tooltip.webp'"
                                                            alt="Gana Gana con MejorCDT" loading="lazy">
                                                        <div class="text-white font-bold text-center text-size-md px-2">
                                                            ¡Estás a un clic de disfrutar tu beneficio!
                                                        </div>
                                                        <div class="text-white text-size-xl px-2">
                                                            <i class="bi bi-arrow-down"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row" *ngIf="promoBunos">
                                                <div class="col-12 mt-2">
                                                    <img [src]="'assets/images/web/bonos/confirmation/banner-promo-simulador-modal-'+cookies.get('promo-bonus')+'.png'"
                                                        alt="" class="w-100 rounded-2">
                                                </div>
                                            </div>

                                            <button id="botonEnviarDatos" type="button"
                                                class="mt-4 btn btn-lg btn-primary-green w-100
                                                ga_resultado_simulador_confirmar_{{ turboCDT ? 'turbo_cdt' : utils.deleteSpace(bank_opencdt).toLowerCase() }}"
                                                (click)="checkParameters()">
                                                Todo está bien <i class="bi bi-check2"></i>
                                            </button>

                                            <button
                                                class="mt-3 btn btn-lg btn-outline-primary-green w-100 btn-close-confirm-parameters"
                                                (click)="closeModalCheck()">
                                                <i class="bi bi-arrow-left"></i> Ir atrás
                                            </button>

                                            <!-- <div class="text-center mt-3">
                                                    <span id="regresar-listado" class="fw-bold fs-5 text-blue-100 pb-2 text-decoration-none cursor-pointer" (click)="activeModal.dismiss()">
                                                        <i class="bi bi-arrow-left"></i>  Ir atrás
                                                    </span>
                                                </div> -->
                                        </form>

                                        <p class="mt-4 mb-0 text-center"
                                            *ngIf="getCampaign?.name != globals_var.CAMPAIGN_CLAUDIAURIBE">
                                            ¿Necesitas ayuda? Escríbenos
                                            <a *ngIf="bankID_opencdt != globals_var.ID_FLAMINGO"
                                                href="https://api.whatsapp.com/send?phone=573160234714&&text=Hola,%20estoy%20en%20la%20p%C3%A1gina%20de%20MejorCDT%20escogiendo%20los%20detalles%20de%20mi%20CDT%20y%20quisiera%20hablar%20con%20un%20Asesor"
                                                target="_blank"
                                                class="text-decoration-none fw-bold text-blue-100">aquí</a>
                                            <a *ngIf="bankID_opencdt === globals_var.ID_FLAMINGO"
                                                href="https://m.mejorcdt.com/t/AtencionCDT-Futuro" target="_blank"
                                                class="text-decoration-none fw-bold text-blue-100">aquí</a>
                                        </p>

                                        <p class="mt-4 mb-0 text-center"
                                            *ngIf="getCampaign?.name === globals_var.CAMPAIGN_CLAUDIAURIBE">
                                            ¿Necesitas de apoyo? <br /> Aplica para una asesoria gratuita con un
                                            <a href="https://viewer.leapchat.io/asesoria-gratuita-mejor-cdt"
                                                target="_blank"
                                                class="text-decoration-none fw-bold text-blue-100">experto de mi
                                                equipo</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showMobile && screen_mobile && bank_allied_mobile " class="check_parameters_mobile p-3">
        <div class="screenBloqe align-items-center" style=" justify-items: center;"
            [ngClass]="{  'd-none': !screenBloqued,  'd-grid' : screenBloqued }">
            <img loading="lazy" [src]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'" alt="">
        </div>


        <div [@fadeInAnimationSuave]="{ value: '', params: { height: '70px' } }"
            *ngIf="turboCDT && !is_amount_disables_turbocdt"
            class="rounded-top-2 text-white position-relative px-0 image-turbo">
            <img loading="lazy" [src]="env.S3_STATIC_FILES + '/images/web/turbocdt/turbo-banner-check-simulate.svg'"
                class="w-100 image-turbo">
        </div>


        <div style="opacity: {{ opacityModalCheckParameters }} ">
            <!-- <div class="ir-atras-check btn-close-confirm-parameters" (click)="activeModal.dismiss()">
                <i class="bi bi-arrow-left"></i> ir atrás
            </div> -->

            <div class="title-6 font-bold  lh-1 my-3">
                <span class="font-semibold">
                    Para continuar con {{ bank_opencdt }},
                </span>
                verifica que los parámetros de tu inversión estén correctos
            </div>

            <div class="alert-info-signature-digital" *ngIf="!bankSettings?.digital_signature">
                Si deseas abrir tu CDT rápido y de forma completamente digital, te recomendamos iniciar tu proceso con
                uno de los bancos marcados con la opción Apertura digital
            </div>

            <form autocomplete="on" method="POST" [formGroup]="FormCheckParameters" class="mt-2">
                <div class="row">
                    <div class="col-12 position-relative">
                        <span class="fw-bold">Quiero abrir un CDT por</span>
                        <i class="bi bi-currency-dollar field-icon"></i>
                        <input id="amount" type="text" placeholder="Monto a consultar."
                            class="form-control mt-2 text-blue-100 fs-4 fw-bold entry-field-icon"
                            formControlName="amount" (keyup)="thousandSeparator($event)" (blur)="_getSimulationInvestment({
                            amount: fCheckParam['amount'].value?.split('.').join(''),
                            terms: terms,
                            include_only:  [bankID_opencdt]
                        })" [attr.tabindex]="-1" [ngClass]="
                            {
                                'is-invalid': submitCheckParam && fCheckParam['amount'].value?.split('.').join('') < bank_amount_validate,
                                'is-valid' : fCheckParam['amount'].value?.split('.').join('') > bank_amount_validate-1
                            }
                        " />
                        <div class="invalid-feedback" [ngClass]="
                            {
                                'd-block': submitCheckParam && fCheckParam['amount'].value?.split('.').join('') < bank_amount_validate,
                                'd-none' : fCheckParam['amount'].value?.split('.').join('') > bank_amount_validate-1
                            }
                        ">
                            El monto minimo debe ser igual o mayor a ${{bank_amount_validate | thousandSeparator}} COP
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-12 position-relative">
                        <span class="fw-bold">Por esperar un plazo de</span>
                        <select [(ngModel)]="selectedItem" name="selectedItem" [ngModelOptions]="{standalone: true}"
                            id="" class="form-select fw-bold fs-4 text-blue-100 mt-2" (change)="ChangeSelectValue()">
                            <option *ngFor="let item of terms" [ngValue]="item">
                                {{ item }} {{ item === 1 ? 'mes' : 'meses' }}
                            </option>
                        </select>
                    </div>
                </div>


                <div class="row mt-3" *ngIf="getCampaign?.name === globals_var.CAMPAIGN_FLAMINGO">
                    <div class="col-sm-7 position-relative">
                        <span class="fw-bold">
                            {{ getCampaign.components.form_minutes_open_cdt.title_option_bank }}
                        </span>
                        <h6 class="text-blue-100 fw-bold fs-4"> {{ selectedBank }} </h6>
                    </div>
                </div>

                <hr class="my-3">

                <div class="row mx-0">
                    <div class="col-6 ps-0">
                        <span class="fw-bold">Que me entregará</span>
                        <div class="fw-light text-black-50 interese-capital"
                            *ngIf="bankID_opencdt === globals_var.ID_FLAMINGO">(intereses + capital)</div>
                        <div class="colorAmount fw-bold fs-3" [ngClass]="{
                          'strikethrough text-black-50': lineThroughInAmount
                        }">
                            ${{ amtGenereteCheckParameters | thousandSeparator }}
                        </div>
                    </div>

                    <div class="col-6 text-end align-self-end px-0">
                        <span class="fw-bold">A una rentabilidad del</span>
                        <div class="colorAmount fw-bold fs-3">
                            {{ placeGenereteCheckParameters }}% EA
                        </div>
                    </div>
                </div>

                <app-widget-event-bonus
                  *ngIf="eventBonus"
                  [amount]="fCheckParam['amount'].value?.split('.').join('')"
                  [term]="this.bankTerm_opencdt"
                  [rate]="this.bankRate_opencdt"
                  [mobileDevice]="true">
                </app-widget-event-bonus>

                <hr class="my-3">

                <section>
                    <div *ngIf="turboCDT && !is_amount_disables_turbocdt"
                        [@fadeInAnimationSuave]="{ value: '', params: { height: '47px' } }"
                        class="row align-content-center justify-content-between align-items-center">
                        <div class="col-7 text-start">
                            <span class="fw-bold">Y quiero hacer el proceso rápido de</span>
                        </div>

                        <div class="col-4 text-end px-0">
                            <img [lazyLoad]="env.S3_STATIC_FILES + '/images/web/turbocdt/logo-turbo-cdt.svg' "
                                [alt]="'Turbo CDT'" class="w-100">
                        </div>

                        <hr class="my-3">
                    </div>

                    <div [@fadeInAnimationSuave]="{ value: '', params: { height: '60px' } }"
                        *ngIf="(turboCDT) || ( !turboCDT && FormCheckParameters.get('method_pay_turbo')?.value == '2') ">
                        <span class="fw-bold"> Porque voy a realizar el pago usando </span>

                        <div class="form-group  mt-3 align-items-center d-flex">
                            <input type="radio" id="method_1" name="method_pay_turbo" value="1"
                                formControlName="method_pay_turbo" class="custom-radio me-2 cursor-pointer"
                                (change)="deactiveTurboCDT($event)" *ngIf="!is_amount_disables_turbocdt">
                            <label for="method_1" class="title-6 cursor-pointer"
                                *ngIf="!is_amount_disables_turbocdt">PSE</label>

                            <input type="radio" id="method_2" name="method_pay_turbo" value="2"
                                formControlName="method_pay_turbo" class="custom-radio me-2 cursor-pointer" [ngClass]="{
                                    'ms-5': !is_amount_disables_turbocdt
                                }" (change)="deactiveTurboCDT($event)">
                            <label for="method_2" class="cursor-pointer">Otro medio de
                                pago</label>
                        </div>

                    </div>
                </section>

                <div class="row" *ngIf="bankID_opencdt === globals_var.ID_FLAMINGO">
                    <div class="col-12 position-relative advisor_tienda " [ngClass]="{'mb-3':was_attended_store}">
                        <span class="fw-bold">¿Fuíste atendido en tienda?</span>

                        <input type="radio" id="btn-yes" name="button-advisor" (change)="advisorStore(1)">
                        <label for="btn-yes" class="me-2">Si</label>

                        <input type="radio" id="btn-no" name="button-advisor" (change)="advisorStore(2)">
                        <label for="btn-no">No</label>
                    </div>
                    <div class="col-12 advisor-question position-relative" *ngIf="was_attended_store">
                        <span class="fw-bold">Por favor ingresa el número de identificación del asesor</span>
                        <i class="bi bi-person-vcard-fill field-icon"></i>
                        <input type="text" class="form-control  fw-bold entry-field-icon"
                            formControlName="number_advisor">
                    </div>
                </div>

                <div class="row" *ngIf="getCampaign?.name === globals_var.CAMPAIGN_CLAUDIAURIBE">
                    <hr class="my-3">
                    <div class="col-12 position-relative advisor_tienda " [ngClass]="{'mb-3':was_attended_store}">
                        <span class="fw-bold">¿Ya tienes un coach financiero desde Money
                            Mindset Solutions?</span>

                        <input type="radio" id="btn-yes" name="button-advisor" (change)="advisorStore(1)">
                        <label for="btn-yes" class="me-2">Si</label>

                        <input type="radio" id="btn-no" name="button-advisor" (change)="advisorStore(2)">
                        <label for="btn-no">No</label>
                    </div>
                    <div class="col-12 advisor-question position-relative" *ngIf="was_attended_store">
                        <span class="fw-bold">¿Cuál es tu coach financiero?</span>
                        <select formControlName="number_advisor" class="form-select">
                            <option *ngFor="let advisor of advisor_claudiauribe" [value]="advisor">
                                {{ advisor }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row" *ngIf="promoBunos">
                    <div class="col-12 mt-2">
                        <img [src]="'assets/images/web/bonos/confirmation/banner-promo-simulador-modal-'+cookies.get('promo-bonus')+'.png'"
                            alt="" class="w-100 rounded-2">
                    </div>
                </div>

                <div class="row" *ngIf="this.cookies.get('refcode')">
                    <div class="col-12 mt-2">
                        <div class="d-flex justify-content-between align-items-center bg-blue-40 rounded-3 p-2">
                            <img class="w-25" [defaultImage]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'"
                                [lazyLoad]="env.S3_STATIC_FILES + '/images/web/landings/referidos/logo-tooltip.webp'"
                                alt="Gana Gana con MejorCDT" loading="lazy">
                            <div class="text-blue-100 font-bold text-center text-size-md px-2">
                                ¡Estás a un clic de disfrutar tu beneficio!
                            </div>
                            <div class="text-blue-100 text-size-xl px-2">
                                <i class="bi bi-arrow-down"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="btn-check-mobile">
                    <button id="botonEnviarDatos" type="button"
                        class="mt-3 btn btn-lg btn-primary-green w-100 ga_resultado_simulador_confirmar_{{ turboCDT ? 'turbo_cdt' : utils.deleteSpace(bank_opencdt).toLowerCase() }}"
                        (click)="checkParameters()">
                        Todo está bien <i class="bi bi-check2"></i>
                    </button>

                    <button class="mt-3 btn btn-lg btn-outline-primary-green w-100 btn-close-confirm-parameters"
                        (click)="closeModalCheck()">
                        <i class="bi bi-arrow-left"></i> Ir atrás
                    </button>

                    <p class="mt-4 mb-0 text-center" *ngIf="getCampaign?.name != globals_var.CAMPAIGN_CLAUDIAURIBE">
                        ¿Necesitas ayuda? Escríbenos
                        <a *ngIf="bankID_opencdt != globals_var.ID_FLAMINGO"
                            href="https://api.whatsapp.com/send?phone=573160234714&&text=Hola,%20estoy%20en%20la%20p%C3%A1gina%20de%20MejorCDT%20escogiendo%20los%20detalles%20de%20mi%20CDT%20y%20quisiera%20hablar%20con%20un%20Asesor"
                            target="_blank" class="text-decoration-none fw-bold text-blue-100">aquí</a>
                        <a *ngIf="bankID_opencdt === globals_var.ID_FLAMINGO"
                            href="https://m.mejorcdt.com/t/AtencionCDT-Futuro" target="_blank"
                            class="text-decoration-none fw-bold text-blue-100">aquí</a>
                    </p>

                    <p class="mt-4 mb-0 text-center" *ngIf="getCampaign?.name === globals_var.CAMPAIGN_CLAUDIAURIBE">
                        ¿Necesitas de apoyo? <br /> Aplica para una asesoria gratuita con un
                        <a href="https://viewer.leapchat.io/asesoria-gratuita-mejor-cdt" target="_blank"
                            class="text-decoration-none fw-bold text-blue-100">experto de mi equipo</a>
                    </p>

                </div>

            </form>
        </div>
    </div>
</div>
