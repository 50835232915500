@if (show) {

  @switch(eventBonus) {
    @case ('MONEYCON') {
      @if (amount < 10000000) {
        <div class="bg-blue-100 mt-3 p-3 rounded-4 text-white" [@fadeInOut]>
          <div class="row g-1 d-flex align-items-center mx-0">
            <div [ngClass]="{
              'col-8': !mobileDevice,
              'col-7': mobileDevice
            }">
              <span [ngClass]="{
                'bg-white text-blue-100 px-3 py-1 rounded-4': !mobileDevice,
                'd-block': mobileDevice
              }">
                Pero por asistir a la
                <span class="font-bold">
                  Moneycon
                </span>
              </span>
              <img
                [src]="env.S3_STATIC_FILES + '/images/web/mejorcdt-blanco.webp'"
                alt="MejorCDT"
                class="mt-3 mb-2" [ngClass]="{
                  'w-50': !mobileDevice,
                  'w-75': mobileDevice
                }">
              <div class="font-light">
                Te regala
                <span class="font-bold" [ngClass]="{
                  'text-size-sm': mobileDevice
                }">
                  + 0.3% E.A
                </span>
                adicional en tu monto inicial
              </div>
            </div>
            <div class="text-end" [ngClass]="{
              'col-4': !mobileDevice,
              'col-5': mobileDevice
            }">
              <div class="font-semibold">
                Y ahora recibirás..
              </div>
              <div class="text-size-lg font-bold">
                ${{ events['MONEYCON'].amountGenerate | thousandSeparator }}
              </div>
            </div>
          </div>
        </div>
      }
      @else {
        <div class="bg-blue-100 mt-3 rounded-4 text-white" [@fadeInOut]>
          <div class="text-center p-3">
            Pero por asistir a la  Moneycon
            <img
              [src]="env.S3_STATIC_FILES + '/images/web/mejorcdt-blanco.webp'"
              alt="MejorCDT"
              class="d-block m-auto"
              width="100px">
            te regala
            <span class="font-bold">
              ${{ events['MONEYCON'].amountBenefit | thousandSeparator }} adicionales
            </span>
            a lo que tenias pensado invertir 😉.
            <div class="font-bold my-2">
              Ahora:
            </div>
          </div>
          <div class="row g-1 d-flex align-items-center text-center mx-0 pt-2 pb-3 rounded-bottom-4 gradient">
            <div class="col-5">
              <span [ngClass]="{
                'bg-white text-size-sm text-blue-100 px-3 py-1 rounded-4': !mobileDevice,
                'text-size-sm': mobileDevice
              }">
                Tu CDT se abrirá por
              </span>
              <div class="text-size-lg font-bold">
                ${{ events['MONEYCON'].amountTotal | thousandSeparator }}
              </div>
            </div>
            <div class="col-2 text-size-xl font-bold">
              <i class="bi bi-arrow-right"></i>
            </div>
            <div class="col-5">
              <span [ngClass]="{
                'bg-white text-size-sm text-blue-100 px-3 py-1 rounded-4': !mobileDevice,
                'text-size-sm': mobileDevice
              }">
                Y recibirás
              </span>
              <div class="text-size-lg font-bold">
                ${{ events['MONEYCON'].amountGenerate | thousandSeparator }}
              </div>
            </div>
          </div>
        </div>
      }

    }
  }

}
