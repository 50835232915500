import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from '@enviroment/environment';
import { fadeInOutAnimation } from '@class/animations/animations';

@Component({
  selector: 'app-widget-event-bonus',
  templateUrl: './widget-event-bonus.component.html',
  styleUrl: './widget-event-bonus.component.css',
  animations: [fadeInOutAnimation]
})
export class WidgetEventComponent implements OnInit, OnChanges {
  private _amount: number = 0;
  @Input() set amount(value: number | string) {
    this._amount = typeof value === 'string' ? Number(value) : value;
  }
  get amount(): number {
    return this._amount;
  }

  @Input() term!: number;
  @Input() rate!: number;
  @Input() mobileDevice!: boolean;

  env = environment;
  eventBonus: string | null = null;
  show: boolean = false;
  deviceMobile?: boolean = false;

  events: any = {
    MONEYCON: {
      minInitialAmount: 1000000,
      amountBenefit: 0,
      amountTotal: 0,
      amountGenerate: 0
    }
  }

  constructor() {}

  ngOnInit(): void {
    this.eventBonus = sessionStorage.getItem('event_bonus');
    this.handleInputsChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const hasChanges = Object.keys(changes).some(key => changes[key]);
    if (hasChanges) {
      this.handleInputsChange();
    }
  }

  handleInputsChange(): void {
    switch (this.eventBonus) {
      case 'MONEYCON':
        this.calculateMoneycon();
        break;

      default:
        break;
    }
  }

  calculateMoneycon(): void {
    if (this.eventBonus) {
      this.show = (this.amount >= this.events[this.eventBonus].minInitialAmount);

      const decimalRate = 0.3 / 100;
      const rate = Math.pow(1 + decimalRate, this.term / 12) - 1;

      this.events[this.eventBonus].amountBenefit  = Math.round(rate * this.amount);
      this.events[this.eventBonus].amountTotal    = this.amount + this.events[this.eventBonus].amountBenefit;

      /* Calcular rentabilidad con el nuevo monto */
      const amount  = this.events[this.eventBonus].amountTotal;
      const rateEA  = this.rate / 100;
      const years   = this.term / 12;
      const amountGenerate = amount * Math.pow(1 + rateEA, years);

      this.events[this.eventBonus].amountGenerate = Math.round(amountGenerate);
    }
  }

}
